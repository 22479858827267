import React, { useState } from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Form } from "react-final-form";
import * as Yup from "yup";
import { TextField, Select, Autocomplete, makeValidate } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import styles from "./CreateConsentTemplateForm.module.scss";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";

const schema = Yup.object().shape({
  name: Yup.string().required(),
  category_id: Yup.string().required(),
  treatment_ids: Yup.array().min(1, "At least one treatment is required"),
});

// Run the makeValidate function...
const validate = makeValidate(schema);

function CreateConsentTemplateForm({
  isOpen,
  close,
  categories,
  treatmentPlans,
  setCurrentTemplate,
}) {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");


  const createConsentTemplate = useMutation(
    (data: any) => {
      const params = data;
      return axios.post(`/consent_clause`, params);
    },
    {
      onSuccess: (data) => {
        try {
          console.log(data);
          setCurrentTemplate(data.data.id);
          queryClient.invalidateQueries({ queryKey: ["consentClauses"] });
        } catch (error) {
          console.error("Error during onSuccess operations:", error);
        }
        close();
      },
      onError: (error: any) => {
        if (error?.response?.data?.detail) {
          toast.error(error?.response?.data?.detail);
        }
      },
    }
  );

  const handleSubmit = async (data) => {
    const treatment_ids = data.treatment_ids.map((treatment) => treatment.id);
    const treatment_id = treatment_ids[0];
    const payload = { ...data, treatment_id, treatment_ids };
    setName(data.name);
    try {
      const response = await createConsentTemplate.mutateAsync(payload);
      return data.name;
    } catch (error) {
      console.error("Error creating consent template:", error);
      return null;
    }
  };

  return (
    <Modal open={isOpen} onClose={close}>
      <div className="modal">
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form
              className={styles.headerForm}
              onSubmit={handleSubmit}
              noValidate
            >
              <TextField
                label="Name"
                name="name"
                required={true}
                variant="outlined"
              />

<Select
                name="category_id"
                label="Category"
                required
                formControlProps={{ margin: "normal" }}
              >
                {categories?.map((category: any) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.category_name}
                  </MenuItem>
                ))}
              </Select>

              <Autocomplete
                name="treatment_ids"
                label="Treatment"
                multiple
                required={true}
                options={treatmentPlans}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value}
              />
             
              <Button
                className={styles.submitButton}
                type="submit"
                variant="contained"
                disabled={createConsentTemplate.isLoading}
              >
                Create
              </Button>
            </form>
          )}
        />
      </div>
    </Modal>
  );
}

export default CreateConsentTemplateForm;
