import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "@ant-design/cssinjs";

// material-ui
import { Box, Grid, Typography, MenuItem, TextField } from "@mui/material";

// project import
import MainCard from "../../components/MainCard";
import TimeCouldSave from "./TimeCouldSave";
import ConsentsCompletedChart from "./ConsentsCompletedChart";
import RevenueBreakdownChart from "./RevenueBreakdownChart";
import TimeSaved from "./TimeSaved";
import DatePickerCard from "../../components/DatePickerCard";
import Loader from "../../components/Loader";
import PDFExportComponent from "../../components/PDFExportComponent";
import Sidebar from "../../menu-items/Sidebar";

/**
 * `HomepageInternal` displays the homepage of the internal dashboard, including a date range picker,
 * filters for location and practice, and several charts like Consents Completed and Revenue Breakdown.
 * It fetches and displays data dynamically based on user selections.
 *
 * The page layout is responsive, adapting to different screen sizes. It utilizes Material UI components
 * for layout and styling, and custom components for specific dashboard functionalities.
 *
 * State hooks manage the selection states for practices and locations, the date range,
 * and handle the dynamic rendering of charts based on these selections.
 *
 * The component fetches lists of locations and practices from the backend upon mount
 * and updates these lists based on user interactions.
 *
 * @returns {React.ReactElement} The JSX markup for the `HomepageInternal` component.
 */
const HomepageInternal = () => {
  const [locationList, setLocationList] = useState([
    { label: "All locations", value: "all" },
  ]);
  const [location, setLocation] = useState("all");

  const [practiceList, setPracticeList] = useState([
    { label: "All practices", value: "all" },
  ]);
  const [practice, setPractice] = useState("all");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const filters = {
    location: location,
    practice: practice,
    startDate: startDate,
    endDate: endDate,
  };

  const chartIds = [
    { id: "maincard_consents_completed", singleRow: false },
    { id: "maincard_revenue_breakdown", singleRow: false },
  ];

  const fileName = "report-homepage.pdf";

  useEffect(() => {
    let isMounted = true;
    const fetchLocationList = async () => {
      try {
        const locationURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/locations`;
        const token = axios.defaults.headers.common.Authorization;
        console.log(token);
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };

        const locationResponse = await axios.get(locationURL, config);
        if (isMounted) {
          if (locationResponse.data && locationResponse.data.length > 0) {
            const updatedLocationList = [
              { label: "All locations", value: "all" },
              ...locationResponse.data,
            ];
            setLocationList(updatedLocationList);
            setLocation(updatedLocationList[0].value);
          }
        }
      } catch (error) {
        console.error("Error fetching location data: ", error);
      }
    };

    fetchLocationList();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchPracticeList = async () => {
      try {
        if (location) {
          const practiceURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/practices?location=${location}`;
          const token = axios.defaults.headers.common.Authorization;
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          const practiceResponse = await axios.get(practiceURL, config);
          if (isMounted) {
            if (practiceResponse.data && practiceResponse.data.length > 0) {
              const updatedPracticeList = [
                { label: "All practices", value: "all" },
                ...practiceResponse.data,
              ];
              setPracticeList(updatedPracticeList);
              setPractice(updatedPracticeList[0].value);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching practice data: ", error);
      }
    };

    fetchPracticeList();
    return () => {
      isMounted = false;
    };
  }, [location]);

  if (locationList.length <= 1 || practiceList.length <= 1) {
    return <Loader />;
  }

  return (
    <Box sx={{ border: "1px solid #ccc", padding: 5, height: "100vh" }}>
      <Grid container spacing={2.5}>
        {/* Sidebar */}
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        {/* Main content */}
        <Grid item xs={12} md={9} lg={10}>
          <Grid container spacing={2.5}>
            {/* row 1 - Filters */}
            <Grid item xs={12}>
              <MainCard content={false}>
                <Box
                  sx={{
                    p: 4,
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    my: 4,
                  }}
                >
                  <TextField
                    id="location-filter"
                    size="small"
                    select
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    sx={{
                      width: { xs: "100%", sm: "30%" },
                      "& .MuiInputBase-input": {
                        py: 0.5,
                        fontSize: "0.875rem",
                      },
                    }}
                  >
                    {locationList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="practice-filter"
                    size="small"
                    select
                    value={practice}
                    onChange={(e) => setPractice(e.target.value)}
                    sx={{
                      width: { xs: "100%", sm: "30%" },
                      "& .MuiInputBase-input": {
                        py: 0.5,
                        fontSize: "0.875rem",
                      },
                    }}
                  >
                    {practiceList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <DatePickerCard
                    onDateChange={handleDateChange}
                    style={{ width: { xs: "100%", sm: "40%" } }}
                  />
                </Box>
              </MainCard>
            </Grid>

            {/* row 2 - Graphs */}
            <Grid item xs={12} md={6} lg={4}>
              <MainCard
                id="maincard_consents_completed"
                content={false}
                sx={{ height: "423px" }}
              >
                <Box
                  sx={{
                    pt: 3,
                    pb: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ textAlign: "center" }}
                  >
                    Consents Completed
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pt: 2,
                    pb: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ConsentsCompletedChart
                    practice={practice}
                    location={location}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MainCard
                id="maincard_revenue_breakdown"
                content={false}
                sx={{ height: "423px" }}
              >
                <Box
                  sx={{
                    pt: 3,
                    pb: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Revenue Breakdown
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pt: 2,
                    pb: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RevenueBreakdownChart
                    practice={practice}
                    location={location}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={12} lg={4} container spacing={2.5}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  mr={1}
                >
                  <TimeSaved
                    className="fixed-size-card"
                    practice={practice}
                    location={location}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "94%", // Ensure the Box takes the full width of the Grid item
                  }}
                >
                  <TimeCouldSave
                    className="could-fixed-size-card"
                    practice={practice}
                    location={location}
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%", // Ensure the inner Box takes the full width of its parent
                      mt: 2, // Add margin-top if needed for spacing
                    }}
                  >
                    <PDFExportComponent
                      filters={filters}
                      chartIds={chartIds}
                      fileName={fileName}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomepageInternal;
