import * as React from 'react';
import { Modal } from '@mui/material';
import cx from 'classnames';
import styles from './ConsentLogs.module.scss';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

function ConsentLogs({ isOpen, closeModal, consentId }) {
  const consentLogs = useQuery(
    ['consentLogs', consentId],
    async () => {
      const { data } = await axios.get(`/consent_bundle/${consentId}/interaction_log`);
      return data;
    },
    {
      enabled: !!consentId,
    }
  );

  return (
    <Modal open={isOpen} onClose={() => closeModal()}>
      <div className={cx('modal', styles.container)}>
        <div className={styles.header}>
          <CloseIcon className={styles.close} onClick={() => closeModal()} />
        </div>
        <div className={styles.content}>
          {consentLogs.isLoading && <div className="loading">Loading...</div>}
          {consentLogs.isSuccess && (
            <table className={styles.logs}>
              <thead>
                <tr>
                  <th>Date Accessed</th>
                  <th>Interaction Log</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(consentLogs.data).map((date) => (
                  <tr key={date}>
                    <td>
                      <h4>{date}</h4>
                    </td>
                    <td>
                      {consentLogs.data[date].map((log, index) => (
                        <div key={index}>
                          <h4>{log.event}</h4>
                          {log.description.length > 0 && (
                            <ul>
                              {log.description.map((description, i) => (
                                <li key={i}>{description}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ConsentLogs;
