import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper
} from '@mui/material';
import MainCard from '../../components/MainCard';
import Sidebar from "../../menu-items/Sidebar";
import axios from 'axios';

const Userfeedback = () => {
  const [data, setData] = useState([]); 
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(20); 




  useEffect(() => {
    const fetchData = async () => {
      const token = axios.defaults.headers.common.Authorization;
      const url = `${process.env.REACT_APP_BASE_URL}admin/reports/user_feedback`;

      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      try {
        const response = await axios.get(url, config);
      
        setData(response.data); 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };


  const downloadAsSVG = async () => {
    try {
      const token = axios.defaults.headers.common.Authorization;
      const url = `${process.env.REACT_APP_BASE_URL}admin/reports/user_feedback`;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(url, config);
  
      if (response.data) {
        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'user_feedback.csv'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error("No data received from API");
      }
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };

  const convertToCSV = (data: any) => {
    const headers = Object.keys(data[0]).join(','); 
    const rows = data
      .map((row: any) =>
        Object.values(row)
          .map((value) => `"${value}"`) 
          .join(',')
      )
      .join('\n');
  
    return `${headers}\n${rows}`;
  };
  
  const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        <Grid item xs={12} md={9} lg={10}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold', 
                fontSize: '2rem',
                marginBottom : '20px' 
              }}
            >
              User Feedback
            </Typography>

            <Button
              variant="contained"
              onClick={downloadAsSVG}
              sx={{
                borderRadius: '20px', 
                textTransform: 'none', 
                padding: '8px 16px',
                marginBottom : '20px'
              }}
            >
              Download as csv
            </Button>
          </Box>

          <MainCard title="">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: '15px', 
                boxShadow: 3, 
                overflow: 'hidden',
                marginTop: 2,
              }}
            >
              <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Treatment Plan ID</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Practice Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Question</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Answer</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  ) : (
                    displayedData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#f5f5f5', // Hover effect
                            cursor: 'pointer',
                          },
                          borderBottom: '1px solid #ddd', // Soft bottom border
                        }}
                      >
                        <TableCell sx={{ borderTopLeftRadius: index === 0 ? '15px' : '0px' }}>
                          {page * rowsPerPage + index + 1} {/* Displaying the index */}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.created_at}</TableCell>
                        <TableCell>{row.treatment_plan_id}</TableCell>
                        <TableCell>{row.practice_name}</TableCell>
                        <TableCell>{row.question}</TableCell>
                        <TableCell>{row.answer}</TableCell>
                        <TableCell sx={{ borderTopRightRadius: index === displayedData.length - 1 ? '15px' : '0px' }}>
                          {row.message}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Userfeedback;
