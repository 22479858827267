import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './PasswordPrompt.module.scss';

const PasswordPrompt = ({ onSubmit, onCancel }) => {
  const [password, setPassword] = useState('');
  const correctPassword = 'medicubeAdmin';
  const [userGroup, setUserGroup] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = () => {
    if (password === correctPassword) {
      onSubmit();
    } else {
      alert('Incorrect password');
    }
  };

  const getPracticeConsentClauseData = async () => {
    try {
      const { data } = await axios.get('/user');
      const userGroup = data[1].group; // Assuming group information is here
      setUserGroup(userGroup);
      setIsAdmin(userGroup === 'admin'); // Check if the user is an admin
      setIsSuperAdmin(userGroup === 'superadmin'); // Check if the user is a super admin

      // Check if the button should be disabled based on user group and URL
      const currentPath = window.location.pathname;
      if (userGroup === 'user' || (userGroup === 'admin' && currentPath === '/password_admin')) {
        setIsDisabled(true); // Disable the button
      } else {
        setIsDisabled(false); // Enable the button
      }
      console.log('User group:', userGroup, 'Current path:', currentPath);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleCancel = () => {
    window.location.replace('/dashboard');
  };

  useEffect(() => {
    getPracticeConsentClauseData();
  }, []);

  return (
    <div className={styles.passwordPrompt}>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter password"
        className={styles.input}
      />
      <button
        onClick={handleSubmit}
        className={`${styles.submitButton} ${isDisabled ? styles.disabledButton : ''}`} // Apply disabled style
        disabled={isDisabled} // Disable the button if conditions are met
      >
        Submit
      </button>
      <button onClick={handleCancel} className={styles.cancelButton}>
        Cancel
      </button>
      {isDisabled && (
        <p className={styles.warningText}>
          Submit is disabled for regular users or admins on the super admin page.
        </p>
      )}
    </div>
  );
};

export default PasswordPrompt;
