import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper
} from '@mui/material';
import MainCard from '../../components/MainCard';
import Sidebar from "../../menu-items/Sidebar";
import axios from 'axios';

const Waitlist = () => {
  const [data, setData] = useState([]); // Data to display in the table
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(20); // Number of rows per page




  useEffect(() => {
    const fetchData = async () => {

      const token = axios.defaults.headers.common.Authorization;
      const url = `${process.env.REACT_APP_BASE_URL}admin/reports/waitinglist`;
;
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      try {
        const response = await axios.get(url, config);
     
        setData(response.data);
      
      } catch (error) {
        console.error('Error fetching data:', error);  
      }
    };

    fetchData();  
  }, []);




  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  // Function to download table as SVG
  const downloadAsSVG = () => {
    // Implement your logic to download the table as SVG
    console.log("Downloading as SVG");
  };

  // Get the records to display on the current page
  const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        <Grid item xs={12} md={9} lg={10}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold', // Make the heading bold
                fontSize: '2rem', // Increase the font size
              }}
            >
            Waiting list
            </Typography>

            
          </Box>

          <MainCard title="">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: '15px', // Round the corners of the table container
                boxShadow: 3, // Soft shadow for the table container
                overflow: 'hidden', // To prevent overflow when rounded
                marginTop: 2,
              }}
            >
              <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell> {/* Index column */}
                   
                    <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Role</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
  {displayedData.length === 0 ? (
    <TableRow>
      <TableCell colSpan={9} align="center">
        No data available
      </TableCell>
    </TableRow>
  ) : (
    displayedData.map((row, index) => (
      <TableRow
        key={index}
        sx={{
          '&:hover': {
            backgroundColor: '#f5f5f5', // Hover effect
            cursor: 'pointer',
          },
          borderBottom: '1px solid #ddd', // Soft bottom border
        }}
      >
        <TableCell sx={{ borderTopLeftRadius: index === 0 ? '15px' : '0px' }}>
          {page * rowsPerPage + index + 1} {/* Displaying the index */}
        </TableCell>
        
        <TableCell>
          {row.email}
          <Button
            variant="outlined"
            size="small"
            sx={{ marginLeft: 1 }}
            onClick={() => navigator.clipboard.writeText(row.email)} // Copy email to clipboard
          >
            Copy
          </Button>
        </TableCell>
        <TableCell>{row.created_at}</TableCell>
        <TableCell>{row.treatment_plan_id}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            size="small"
            sx={{ marginLeft: 1 }}
            onClick={() => console.log(`Contacting ${row.email}`)} // Simulating contact action
          >
            Contact
          </Button>
        </TableCell>
      </TableRow>
    ))
  )}
</TableBody>

              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Waitlist;
