import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "./TemplateEditor.module.scss";
import { Form } from "react-final-form";
import { Radios, Checkboxes } from "mui-rff";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Modal,
  CircularProgress,
  Box,
  Typography,
  Button,
} from "@mui/material";
import {
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import ImageGallery from "../ImageGallery/ImageGallery";

const TemplateEditor = ({ selectedTemplate, onSave, refetch }) => {
  const editorRef = useRef(null);
  const [category, setCategory] = useState("");
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [templateName, setTemplateName] = useState(
    selectedTemplate?.name || ""
  );
  const [isAdmin, setIsAdmin] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showGallery, setShowGallery] = useState(false);

  
  const { data: categoryData, isLoading: isCategoryLoading } = useQuery(
    ["categories"],
    async () => {
      const { data } = await axios.post("/treatment_category");
      return data;
    }
  );

  const { data: treatmentPlans, isLoading: isTreatmentPlansLoading } = useQuery(
    ["treatmentPlans"],
    async () => {
      const { data } = await axios.get(`/treatment`);
      return data;
    },
    {
      onSuccess: (data) => {
        const plans = data.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
          value: plan.id,
        }));
        setTreatmentOptions(plans);
      },
    }
  );

  useEffect(() => {
    const fetchIsAdmin = async () => {
      try {
        const { data } = await axios.get("/user/is_admin");
        setIsAdmin(data.isAdmin);
      } catch (error) {
        console.error("Error fetching admin status:", error);
      }
    };

    fetchIsAdmin();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      setTemplateName(selectedTemplate.name);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/ckeditor/ckeditor.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("CKEditor script loaded.");
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (editorRef.current && window.CKEDITOR) {
      const editorInstance = window.CKEDITOR.replace(editorRef.current, {
        height: "500px", 
        resize_enabled: true, 
        resize_dir: "vertical", 
      });

      const initializeEditorclean = () => {
        const x = window.CKEDITOR.instances;
        const instanceKeys = Object.keys(x);
  
        if (Object.keys(x).length > 0) {
          console.log("Destroying existing CKEditor instance...");
          instanceKeys.forEach((key) => {
            window.CKEDITOR.instances[key].destroy(true);
            console.log(`${key} instance destroyed.`);
          });
        } else {
          console.log(true);
        }
      };

      editorInstance.on("instanceReady", () => {
        if (selectedTemplate?.body) {
          editorInstance.setData(selectedTemplate.body);
        } else {
          editorInstance.setData("");
        }
      });

      return () => {
        if (editorInstance) {
          editorInstance.destroy(true);
        }
      };
    }
  }, [selectedTemplate]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    onSave(templateName, e.target.value, selectedTreatments);
  };

  const handleTreatmentChange = (event, value) => {
    setSelectedTreatments(value);
    onSave(templateName, category, value);
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
    onSave(e.target.value, category, selectedTreatments);
  };

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.category_id) {
      setCategory(selectedTemplate.category_id);
    }

    if (selectedTemplate && selectedTemplate.treatment_ids) {
      const preselectedTreatments = treatmentOptions.filter((option) =>
        selectedTemplate.treatment_ids.includes(option.value)
      );
      setSelectedTreatments(preselectedTreatments);
    }
  }, [selectedTemplate]);

  const uploadCallback = (file) => {
    console.log(file);
    const token = axios.defaults.headers.common.Authorization;
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append(`file`, file);

   
      axios
        .post(`/consent_clause/upload_image_to_s3`, formData)
        .then(() => {
        
          return axios.post(
            "consent_clause/upload_user_media_to_s3",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
              },
            }
          );
        })
        .then((response) => {
      
          toast.success("Content added to the Image gallery");
          resolve(response); 
        })
        .catch((err) => {
          reject(err); 
        });
    });
  };

  const handleSave = async (values) => {
    const editorContent = window.CKEDITOR.instances.editor1.getData();

    const base64Regex = /<img[^>]+src="data:image\/[^;]+;base64,[^"]+"[^>]*>/g;
    const base64Images = editorContent.match(base64Regex);

    let updatedContent = editorContent;

    if (base64Images) {
      for (const base64ImgTag of base64Images) {
        const base64Data = base64ImgTag.match(
          /src="(data:image\/[^;]+;base64,[^"]+)"/
        )?.[1];
        
        if (base64Data) {
          try {
            const blob = await fetch(base64Data).then((res) => res.blob());
            const file = new File([blob], "uploaded_image.png", {
              type: blob.type,
            });
            const uploadResponse = await uploadCallback(file);
            const imageUrl = uploadResponse.data;
            updatedContent = updatedContent.replace(base64Data, imageUrl);
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      }
    }
    
    const payload = {
      body: updatedContent,
      category_id: values.category,
      is_cloned: false,
      is_default: values.is_default,
      level: values.level,
      name: values.templateName,
      parent_id: null,
      treatment_id: values.selectedTreatments[0]?.value || null,
      treatment_ids: values.selectedTreatments.map(
        (treatment) => treatment.value
      ),
    };

  
    if (!payload.is_default) {
      axios
        .put(`/consent_clause/${selectedTemplate.id}`, payload)
        .then((response) => {
          console.log("Template updated successfully:", response.data);
          toast.success("Template updated successfully!");
          // editorInstance.setData(updatedContent);
          if (refetch) refetch();
          if (onSave) onSave(payload);
        })
        .catch((error) => {
          console.error("Error updating template:", error);
        });
      return;
    }

    const id = selectedTemplate?.id;

    const validationResponse = await validateConsentClause(payload, id);
    console.log("Validation response:", validationResponse);

    if (validationResponse.result === true) {
      axios
        .put(`/consent_clause/${id}`, payload)
        .then((response) => {
          //console.log("Template updated successfully:", response.data);
          toast.success("Template updated successfully!");
          if (refetch) refetch();
          if (onSave) onSave(payload);
        })
        .catch((error) => {
          console.error("Error updating template:", error);
          toast.error("Failed to update template. Please try again.");
        });
    } else {
      let existingTreatments = "";

      if (Array.isArray(validationResponse.data)) {
        existingTreatments = validationResponse.data
          .map((item) => `<li>${item.consent_clause_name}</li>`)
          .join("\n");
      } else {
        console.error("Expected validationResponse.data to be an array.");
      }

      const existingTreatmentsList = `<ul class="custom-list">${existingTreatments}</ul>`;
      const note = `<span>(Note that if you proceed to save, the current consent clause will be made
        default and any other consent clauses will be removed as default for the above treatments)</span>`;
      const abc = `<ul class="custom-list" style="text-align: left;"><strong></strong> ${existingTreatments}</ul>`;
      const title = `One or more selected treatments have been set default in another consent clause`;

      confirmAlert({
        title: <div dangerouslySetInnerHTML={{ __html: title }} />,
        message: (
          <div>
            <br />
            <br />
            <div dangerouslySetInnerHTML={{ __html: abc }} />
            <br />
            <hr />
            <div dangerouslySetInnerHTML={{ __html: note }} />
          </div>
        ),
        buttons: [
          {
            label: "Cancel",
            onClick: () => {
              console.log("User canceled the action.");
            },
          },
          {
            label: "Save Anyway",
            onClick: async () => {
              try {
                const response = await axios.put(
                  `/consent_clause/${id}`,
                  payload
                );
                console.log("Template updated successfully:", response.data);
                toast.success("Template updated successfully!");
                if (onSave) onSave(payload);
              } catch (error) {
                console.error("Error updating template:", error);
                toast.error("Failed to update template. Please try again.");
              }
            },
          },
        ],
      });
      
    }
  };

  const validateConsentClause = async (payload, id) => {
    try {
      const response = await axios.put("consent_clause/validation_check", {
        id: id,
        treatment_ids: payload.treatment_ids,
        level: payload.level,
      });

      // Return the response data, assuming the validation result is in `response.data`
      return response.data;
    } catch (error) {
      console.error("Error in validation check:", error);
      return {
        result: false, // Ensure a default structure in case of failure
        message: "Validation failed",
        data: [],
      };
    }
  };


  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        templateName,
        category,
        selectedTreatments,
        level: selectedTemplate?.level || "user",
        is_default: selectedTemplate?.is_default || false,
      }}
    >
      {({ handleSubmit }) => (
        <form className={styles.templateEditor} onSubmit={handleSubmit}>
          {selectedTemplate ? (
            <div className={styles.editorWrapper}>
        
              <div className={styles.editorContainer}>
                <h2>Name: {selectedTemplate.name}</h2>

                <input
                  type="text"
                  className={styles.inputField}
                  value={templateName}
                  onChange={handleTemplateNameChange}
                  placeholder="Edit Template Name"
                />

                {!(
                  selectedTemplate?.type === "intro" ||
                  selectedTemplate?.type === "outro"
                ) && (
                  <select
                    className={styles.dropdown}
                    value={category || selectedTemplate.category_name}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select Category</option>
                    {isCategoryLoading ? (
                      <option disabled>Loading categories...</option>
                    ) : (
                      categoryData?.map((cat) => (
                        <option key={cat.id} value={cat.id}>
                          {cat.category_name}
                        </option>
                      ))
                    )}
                  </select>
                )}

                
                {!(
                  selectedTemplate?.type === "intro" ||
                  selectedTemplate?.type === "outro"
                ) && (
                  <Autocomplete
                    multiple
                    options={treatmentOptions}
                    value={selectedTreatments}
                    onChange={handleTreatmentChange}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Treatments"
                        placeholder="Select Treatments"
                      />
                    )}
                    isLoading={isTreatmentPlansLoading}
                  />
                )}

                <br />

              <button
                  type="button"
                 onClick={() => setShowGallery(!showGallery)}
                  
                  style={{
                    backgroundColor: "#ff9500",
                    color: "black",
                    textAlign: "right",
                    float: "right",
                  }}
                >
                  {showGallery ? "Close Gallery" : "Open Gallery"}
                </button> 

                <br />
                <br />
                <br />

                
                <textarea
                  ref={editorRef}
                  className={styles.editorTextarea}
                  id="editor1"
                />

                <br />

                {selectedTemplate?.type === "custom" && (
                  <Radios
                    label="Level"
                    name="level"
                    required={true}
                    data={[
                      { label: "User", value: "user" },
                      {
                        label: "Practice",
                        value: "practice",
                        disabled: isAdmin?.data,
                      },
                    ]}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  />
                )}

                <br />

                {selectedTemplate?.type === "custom" && (
                  <Checkboxes
                    name="is_default"
                    data={{ label: "Save as default", value: true }}
                  />
                )}

                <br />
                <br />
                <button type="submit" className={styles.saveButton}>
                  Save
                </button>
              </div>

              {showGallery && (
                <div className={styles.imageGalleryContainer}>
                 <ImageGallery showGallery={showGallery} onClose={() => setShowGallery(false)} />
                 
                </div>
              )}
            </div>
          ) : (
            <p>Loading.....</p>
          )}
        </form>
      )}
    </Form>
  );
};

export default TemplateEditor;
