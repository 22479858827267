import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import styles from "./ImageGallery.module.scss";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

const ImageGallery = ({ showGallery, onClose }) => {
  const [activeTab, setActiveTab] = useState("stock_images");

  const [galleryData, setGalleryData] = useState({
    stock_gifs: [],
    stock_images: [],
    private: [],
  });

  const [uploadedImages, setUploadedImages] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isConfidential, setIsConfidential] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchGalleryData = async (searchQuery = "") => {
    try {
      const token = axios.defaults.headers.common.Authorization;
      if (!token) {
        console.error("Authorization token is missing.");
        return;
      }
      setLoadingSearch(true);
      const response = await axios.get(
        `consent_clause/get_user_media?search=${searchQuery}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setGalleryData(response.data);
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchGalleryData(searchQuery);
    }, 500); // Debounce time (500ms)

    return () => clearTimeout(debounceTimeout);
  }, [searchQuery]);



  const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };


  const renderImages = () => {
    return galleryData[activeTab].map((item) => {
      const keywords = typeof item.keywords === "string"
        ? item.keywords.split(",").map((keyword) => keyword.trim()) // Split and trim
        : [];
  
      return (
        <div key={item.id} className={styles.imageItemContainer}>
          <div className={styles.imageItem}>
            <div className={styles.imageWrapper}>
              <Tooltip title={keywords.join(", ")}>
                <div className={styles.imageContainer}>
                  <img
                    src={item.url}
                    alt={item.name}
                    className={styles.galleryImage}
                  />
                </div>
              </Tooltip>

              {/* Duration Overlay */}
              {item.duration && (
                <div className={styles.durationOverlay}>
                  {formatDuration(item.duration)}
                </div>
              )}

              {/* Confidential Icon */}
              {item.is_confidential && (
                <Tooltip title="Confidential">
                  <GppMaybeIcon className={styles.confidentialIcon} />
                </Tooltip>
              )}
            </div>
          </div>

          {/* Delete Button for Private Tab */}
          {activeTab === "private" && (
            <IconButton
              className={styles.deleteButton}
              onClick={() => handleDeleteImage(item.id)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      );
    });
  };
  
  
  

  const handleDeleteImage = async (mediaId) => {
    try {
      const token = axios.defaults.headers.common.Authorization;
      if (!token) {
        toast.error("Authorization token is missing.");
        return;
      }
      await axios.put(
        `/consent_clause/archive_user_media/${mediaId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Media archived successfully.");
      fetchGalleryData();
    } catch (error) {
      console.error("Error archiving media:", error);
      toast.error("Failed to archive media.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedImages((prevImages) => [...prevImages, ...acceptedFiles]);
    },
    multiple: true,
  });

  const removeImage = (index) => {
    const newImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(newImages);
  };

  const handleImageUpload = async () => {
    const token = axios.defaults.headers.common.Authorization;
    setLoading(true);
    console.log(uploadedImages);
    if (uploadedImages.length > 0) {
      try {
        for (const file of uploadedImages) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("is_confidential", isConfidential ? "true" : "false");

          await axios.post("consent_clause/upload_user_media_to_s3", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          });
        }

        toast.success("Content added to the Image gallery");
        fetchGalleryData();
        closeImageModal();
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Error uploading images");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("No images to upload.");
      setLoading(false);
    }
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setUploadedImages([]);
    setIsConfidential(false);
  };

  useEffect(() => {
    if (showGallery) fetchGalleryData();
  }, [showGallery]);

  if (!showGallery) return null;

  return (
    <div>
      <div className={styles.header}>
        <button
          type="button"
          onClick={fetchGalleryData}
          className={styles.refreshButton}
        >
          <RefreshIcon />
        </button>
        <h3 className={styles.topic}>Image Gallery</h3>
        <div className={styles.actions}>
          <Button
            variant="contained"
            onClick={() => setIsImageModalOpen(true)}
            className={styles.uploadButton}
          >
            +
            <PhotoCameraBackIcon
              style={{ marginLeft: "10px", fontSize: "20px" }}
            />
          </Button>
        </div>
      </div>

      <div className={styles.tabContainer}>
        {["stock_images", "stock_gifs", "private"].map((tab) => (
          <button
            key={tab}
            type="button"
            className={`${styles.tabButton} ${
              activeTab === tab ? styles.activeTab : ""
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.replace("_", " ").toUpperCase()}
          </button>
        ))}
      </div>

      <div>
        {(activeTab === "stock_images" || activeTab === "stock_gifs") && (
          <div className={styles.searchBarContainer}>
            <input
              type="text"
              placeholder="Search..."
              className={styles.searchInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}

        {loadingSearch && <p>Loading...</p>}

        <div className={styles.imageGallery}>{renderImages()}</div>
      </div>

      <ImageUploadModal
        open={isImageModalOpen}
        onClose={closeImageModal}
        onUpload={handleImageUpload}
        uploadedImages={uploadedImages}
        onRemoveImage={removeImage}
        getInputProps={getInputProps}
        isConfidential={isConfidential}
        getRootProps={getRootProps}
        setIsConfidential={setIsConfidential}
      />
      <LoadingModal open={loading} />
    </div>
  );
};

const ImageGalleryTooltip = () => (
  <div>
    <Typography variant="h6" style={{ marginBottom: "10px" }}>
      Image Gallery :
    </Typography>
    <List style={{ fontSize: "16px" }}>
      <ListItem>
        <ListItemText primary="Upload directly from the upload image button." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Add image to editor and save; it will automatically be added to the gallery." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Use your mobile to capture and upload images (Coming soon!)." />
      </ListItem>
    </List>
  </div>
);

const ImageUploadModal = ({
  open,
  onClose,
  onUpload,
  uploadedImages,
  onRemoveImage,
  getInputProps,
  getRootProps,
  isConfidential,
  setIsConfidential,
}) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: 24,
        minWidth: "1000px",
        minHeight: "900px",
        maxWidth: "1000px",
        maxHeight: "900px",
        overflow: "auto",
      }}
    >
      <h2>Upload Images To Gallery</h2>
      <Button
        variant="outlined"
        component="label"
        fullWidth
        style={{
          marginBottom: "10px",
          padding: "10px 20px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        Choose Images/Gif
        <input type="file" hidden {...getInputProps()} />
      </Button>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #003366",
          padding: "160px",
          textAlign: "center",
          borderRadius: "8px",
          backgroundColor: "#f8f8f8",
          marginBottom: "20px",
        }}
      >
        <p>Drag and drop images/Gif here</p>
        <p>or</p>
        <p>Click to upload images/Gif</p>
      </div>
      <div
        style={{
          marginTop: "20px",
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {uploadedImages.map((file, index) => (
          <div key={index} style={{ position: "relative", margin: "10px" }}>
            <img
              src={URL.createObjectURL(file)}
              alt="Preview"
              style={{
                maxWidth: "900px",
                maxHeight: "200px",
                objectFit: "contain",
                marginBottom: "10px",
              }}
            />
            <Button
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                padding: "5px",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                color: "white",
                borderRadius: "10%",
              }}
              onClick={() => onRemoveImage(index)}
            >
              <DeleteIcon />
            </Button>
          </div>
        ))}
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <label style={{ marginBottom: "10px" }}>
          <input
            type="checkbox"
            checked={isConfidential}
            onChange={(e) => {
              setIsConfidential(e.target.checked);
            }}
          />
          Mark this images as confidential
        </label>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            padding: "12px 20px",
            borderRadius: "8px",
            flex: 1,
            marginRight: "10px",
          }}
          onClick={onUpload}
          disabled={uploadedImages.length === 0}
        >
          Upload Images
        </Button>
        <Button
          variant="outlined"
          style={{
            padding: "12px 20px",
            borderRadius: "8px",
            flex: 1,
            marginLeft: "10px",
          }}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Box>
  </Modal>
);

const LoadingModal = ({ open }) => (
  <Modal open={open}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "30px",
        borderRadius: "10px",
        textAlign: "center",
      }}
    >
      <CircularProgress size={50} />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Uploading data to cloud storage...
      </Typography>
    </Box>
  </Modal>
);

export default ImageGallery;
